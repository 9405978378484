.banner-card{
    padding: 0;
}
.banner-container {
    padding: 2rem 1rem;
    color: black;
}
.banner-header {
    margin-bottom : 1rem;
}
.banner-images {
    display: none;
}
.banner-button {
    background-color: #A8BF54;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    border-color: transparent;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
.banner-area {
    width: 100%;
    /* background-image: url(../images/banner.png); */
    /* background-image: url(../../../public/Images/banner.png); */
}

.banner-area h1 {

    /* H1 */
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 350;
    font-size: 45px;
    line-height: 139.02%;
    /* or 71px */
    color: #FFFFFF;

}

.noPad {
    padding: 0;
}

.noMargin {
    margin: 0;
}

.wrapper-1 {
    background-size: cover;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    flex-wrap: wrap;
    line-height: 139.02%;
    color: #FFFFFF;
    text-align: left;
}
.circled-image {
    height: 150.15px;
    width: 150.15px;
    border-radius: 50%;
    border: 2px solid white;
}
.img-bg {
    position: absolute;
    width: 170px;
    height: 172px;
    left: 100px;

}

.img-bg-1 {
    position: absolute;
    width: 170px;
    height: 172px;
    top: 120px;

}


.img-bg-2 {
    position: absolute;
    width: 172px;
    height: 172px;
    top: 120px;
    left: 200px;

}

.img-bg-3 {
    position: absolute;
    width: 172px;
    height: 172px;
    left: 100px;
    top: 240px;

}

.banner-content-container {
    margin: 0 auto;
    background: #F5F5F5;
}

@media screen and (min-width: 992px) {
    .banner-container {
        padding: 7rem 4rem;
    }
    .banner-images {
        display: flex;
    }
} 

@media screen and (min-width: 1200px) {
    .banner-row {
        position: absolute;
        top: 0;
    }
    .banner-container {
        padding: 7rem 4rem;
        color: white;
        text-align: left;
    }
    .banner-content-container {
        background: transparent;
    }
    .banner-images {
        display: flex;
        position: absolute;
        top: 15%;
    }
}

@media screen and (min-width: 1400px) {
    .banner-images {

        top: 15%;
    }
    .banner-container {
        padding-top: 12%;
    }    
}

@media screen and (min-width: 2560px) {
    .banner-images {

        top: 30%;
    }
    .banner-container {
        padding-top: 20%;
    }    
}