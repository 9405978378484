.enrollment-page {
    padding: 2rem 1rem;
    background-color: #F5F5F5;
    
}
.enrollment-header {
    color:#A8BF54;
    text-align: left;
    padding: 0 0 2rem;
}   

@media screen and (min-width: 992px) {

}