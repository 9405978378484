/*dashboard page css*/
.dashboard-main{
    /*top: 100px;
    margin-top: 5%;*/
    padding: 20px;
    margin: 0;
    height: 100vh;
}
.dashboard-main .caret{
    display: none;
}
.dashboard-main .page-link{
    color: #43594D;;
}
.dashboard-main .pagination{
    flex-direction: inherit;
}
.dashboard-main .page-item.active .page-link{
    background-color: #43594D;
    border-color: #43594D;
}
.dashboard-main #pageDropDown{
    box-shadow: none;
}
.dashboard-main .btn,
.dashboard-main input,
.dashboard-main select
{
    font-size: 12px !important;
    text-align: left;
}
.dashboard-main .btn {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    line-height: 17px;
    padding: 7px 30px;
}
.dashboard-main .form-control {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    height: auto;
}
.dashboard-main .react-bs-table-csv-btn{
    color: #fff;
    border-radius: 2px;
    box-shadow: 5px 5px 5px #dadada; padding: 7px 30px 7px 15px;
    background-color:  #5a5f85;
    border-color: #5a5f85;
}
.inputiconwrap{
    position: relative;
}
.modalHeader{ 
    position: relative;
    border: none;
    margin: 28px 10px;
}
.modalBody .dropdown-wrapper{
    position: relative;
}
li{
    list-style-type: none;
}
.modalBody input{
    width: 97%;
    padding: 10px;
    border: 2px solid #656060;
    border-radius: 5px;
    margin: 5px 0;
}
.modalBody .dropdown{
    position: absolute;
    top: 10px;
    right: 13px;
}
.modalFooter{
    border: none;
}
.addMoresection{
    position: absolute;
    background: #43594D;
    border-radius: 20px;
    min-width: 194px;
    padding: 10px 30px 0;
    right: 12px;
}
.dropdown-section{
    top: 11px;
    position: absolute;
    background: #43594D;
    border-radius: 14px;
    min-width: 125px;
    right: 24px;
}
.dropdown-section .btn:hover {
    color: #fff;
}
.dropdown-section i{
    color: #fff;
    font-size: 0.9rem;
    padding: 0 0 0 5px;
    vertical-align: inherit;
}
.dropdown-section ul li{
    font-size: 12px;
    color: white;
}
.dropdown-section ul li:hover{
    background-color: #a8bf53;
}

.loginsection{
    position: relative;
    background: #43594D;
    border-radius: 20px;
    /* height: 75px; */
    min-width: 194px;
    padding: 10px 30px 0;
    z-index: 2;
}
.loginsection span, .addMoresection span{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 0 10px;
}
.loginsection span a:hover, .addMoresection span a:hover{
    color: #fff;
}
.loginsection ul{
    color: #fff;
    font-size: 1rem; 
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin: 0;
    border-top: 1px solid #fff;
}
.loginsection li{
    list-style-type: none;
    padding: 6px 0;
}
.loginsection .btn :hover{
    background: #43594D;
}
/* .loginsection i.bi-person-circle {
    position: absolute; top: 8px;
} */
.loginsection li a{
  cursor: pointer;
  color: #fff;
  font-size: 0.7rem;
  padding: 0;
}
.loginsection li a i{
    padding: 5px 5px 5px 0px;
    font-size: 1rem;
  }
  .modalFooter .statusactive{
    background: #a8bf53;
    border-color: #a8bf53;
  }
.inputiconwrap .form-select{
    background-image: none;
    border-radius: 50px;
}
.inputiconwrap .form-select:focus, .inputiconwrap input.form-control:focus, button.btn.btn-secondary:focus, button.btn.btn-secondary:active, button.btn.btn-primary:focus, button.btn.btn-primary:active{
    outline: 0;
    box-shadow: none;
    border: 1px solid #ccc;
}
.react-bootstrap-table{
    text-align: left;
}
/* .react-bootstrap-table-editing-cell select:focus{
    outline: 0;
    box-shadow: none;
    border: 5px solid #a8bf53;
} */
.inputiconwrap i{
    position: absolute; top: 8px; right: 8px;
}
.inputiconwrap input.form-control{
    border-radius: 50px;
}
.bi.bi-download{ color: #fff; } 
.expand-cell{
    width: 20%;
}
.expand-cell-header b{
  display: none;
}
.expand-cell{
    text-align: center;
}
.reset-expansion-style{ padding: 0px !important;}
.row-expansion-style{
    background-color: #f1f1f1;
}
.btn-sort{
    background-color: ffffff;
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    border-radius: 2px !important;
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
   
}
.search-label{
    width: 100%;
}
.search-label span{
    display: none;
}
#pageDropDown{
    float: left;
}
.dropdown-item>a{ color: #000;}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}
.react-bootstrap-table tr td {
    vertical-align: middle;
}
.react-bootstrap-table td.expand-cell{
    cursor: pointer;
}
.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
    margin: 10px 6.5px;
}
/* status btn */
.statusactive, .statusall{
    color: #fff !important;
    background-color: #a8bf53 !important;
    border-color: #a8bf53 !important;
}

.statusinactive{
  color: #fff !important;
  background-color: #f5683b !important;
  border-color: #f5683b !important;
}
.statusdormant{
  color: #fff !important;
  background-color: #edbc3f !important;
  border-color: #edbc3f !important;
}
.bgDarkGrey {
    background-color: rgb(128,96,67,.9)!important;
}




.arrowExpand{
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    transition: all 0.5s;
 }
 .btn-secondary,
 .btn-secondary:hover,
 .btn-secondary:active:hover,
 .btn-secondary.active:hover,
 .open>.dropdown-toggle.btn-secondary:hover,
 .btn-secondary:focus,
 .btn-secondary:active:focus,
 .btn-secondary.active:focus,
 .open>.dropdown-toggle.btn-secondary:focus,
 .btn-secondary:active.focus,
 .btn-secondary.active.focus,
 .open>.dropdown-toggle.btn-secondary.focus { 
     color: #606060;
     border: 1px solid #e8e8e8;
     background-color: none;
     background: none;
     
 }
 .form-control {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    height: auto;
  }
.form-control.disabled{
    opacity: 1 !important;
}
.form-group{
    margin: 10px;
}
.row-expansion-style{
    padding: 10px;
}
.filter-label{
    display: none;
}
.disabled input,.disabled textarea{
    background: #fff !important;
    opacity: 0.8!important;
}
.commentEditBtn{
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}
#commentText{
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 3px 10px;
}
.main-container{
    margin: 0;
    padding: 0;
    position: relative;
}
.main-container-leftnav{
    margin: 0;
    padding: 0;
    background: #43594d;
    height: 100%;
    position: fixed;
}
.main-wrapper{
    position: absolute;
    right: 0;
}
.wrapper-container{
    position: absolute;
    right: 15px;
    top: 15px;
}
ul.aofwrap{
    display: flex !important;
    flex-direction: row !important;
}
.aofwrap li{
    display: inline-block;
    margin: 5px;
}
.aofwrap li img{
    display: block;
}
.rowwrapdetails input{
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
@media (min-width: 320px) and (max-width: 995px) {
    .main-wrapper{
        top: 197px;
        z-index: 1;
    }
    .main-container-leftnav{
        position: fixed;
    height: 192px; 
    z-index: 5;
    }
  /* #leftNav {
    position: absolute;
    z-index: 9999;
    width: 50%;
  } */
}