.bgGreen {
  background: #43594d;
}

.margin-0-right {
  margin-right: 0px;
}

.fullHeight {
  height: 100%;
}

/* #leftNav {
  position: fixed;
} */

#leftNav a {
  margin: 0.4rem 0;
  padding: 0.5rem 1rem;
}

#leftNav i {
  color: white;
  font-size: 1.2rem;
}

#leftNav .isactive {
  color: black;
  background-color: aliceblue;
}

#leftNav .isactive i {
  color: black;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  text-align: left;
}

ul a {
  font-size: 0.9rem;
  padding: 5px;
  color: black;
  font-weight: 600;
}

ul a:hover {
  color: black;
}

@media (min-width: 320px) and (max-width: 995px) {
    .main-wrapper{
        top: 197px;
        z-index: 100;
    }
    .main-container-leftnav{
        position: fixed;
    height: 192px;
    }
  /* #leftNav {
    position: absolute;
    z-index: 9999;
    width: 50%;
  } */
}
