.form-container {
    padding: 1.5rem 2rem;
    border: 1px solid #3E3E3E;
    border-radius: 5px;
}
.field-container {
    text-align: left;
    margin-bottom: 2rem;
}
.field-container label {
    color: #3A3C3A;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    font-family: Inter;
    line-height: 1;
}
.association-label {
    color: #3A3C3A;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-family: Inter    
}
.field-container input[type='text'],
.field-container input[type='email'],
.field-container input[type='date'],
.field-container textarea,
.field-container select {
    height: 49px;
    border: 1px solid #3E3E3E;
    width: 100%;
    font-size: 1rem;
    font-family: Inter;
    border-radius: 3px;
    outline: none;
    border-radius: 5px;
    font-weight: 300;
    color: #333;
    border: 1px solid #aaa;
    padding: 0 15px; 
}
.field-container select {
    padding: 0 30px 0 15px;
}
.field-container input[type='text']::placeholder,
.field-container input[type='email']::placeholder,
.field-container input[type='date']::placeholder,
.field-container textarea::placeholder,
.field-container select::placeholder {
    font-size: 1rem;
    color: #BBBBBB;
    font-family: Inter;

}
.field-container textarea {
    border: 1px solid #3E3E3E;
    width: 100%;
    font-size: 1rem;
    border-radius: 3px;
    outline: none;
    border-radius: 5px;
    font-weight: 300;
    color: #333;
    border: 1px solid #aaa;
    padding: 5px 15px;
}
.field-container textarea::placeholder {
    font-size: 1rem;
    color: #BBBBBB;
    font-family: Inter;
    padding: 12px 12px;
}
.green-radio {
    width: 2rem;
    height: 2rem;
    visibility: hidden;
}

input[type='radio']:after {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #A8BF54;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.image-checkbox {
    display: none;
}
.image-select {
    width: 82px;
    height: 112px;
    border: 3px solid transparent;
    border-radius: 5px;
    border-width: 10px 5px;    
}
.reset-expansion-style .rowwrapdetails input{
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    margin: 5px 0;
}
.field-container .image-label {
    display: inline-block;
    width: auto;
    margin: 15px;
}
.image-checkbox:checked + .content-writing-label .image-select {
    border: 3px solid rgba(127, 242, 183, 1);
    border-width: 10px 5px;  

}
.image-checkbox:checked + .field-operations-label .image-select {
    border: 3px solid rgba(233, 152, 116, 0.7);
    border-width: 10px 5px;  
    /* border-opa */
}
.image-checkbox:checked + .fund-raising-label .image-select {
    border: 3px solid rgba(63, 65, 78, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .information-technology-label .image-select {
    border: 3px solid rgba(239, 178, 60, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .employment-label .image-select {
    border: 3px solid rgba(128, 138, 255, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .social-media-marketing-label .image-select {
    border: 3px solid rgba(250, 110, 90, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .forestry-consulting-label .image-select {
    border: 3px solid rgba(108, 178, 142, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .fellowship-program-label .image-select {
    border: 3px solid rgba(233, 152, 116, 0.7);
    border-width: 10px 5px;  
}
.image-checkbox:checked + .others-label .image-select {
    border: 3px solid rgba(63, 65, 78, 0.7);
    border-width: 10px 5px;  
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #A8BF54;

  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #A8BF54;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.green-checkbox {
    width: 2rem;
    height: 2rem;
    visibility: hidden;
}

.green-checkbox:after {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.green-checkbox:checked:after {
    width: 2rem;
    height: 2rem;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #A8BF54;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.banner-button {
    background-color: #A8BF54;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    border-color: transparent;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
}

@media screen and (min-width: 546px) {
    .banner-button {
        width: auto;
    }
}